 
$(document).ready(function(){

    // home page slide
    $('.autoplay').slick({
        dots: false,
       
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow:'<i class=" cursor_thumb fa-solid fa-chevron-right  next-arrow"></i>',
        nextArrow:'<i class=" cursor_thumb  fa-solid fa-chevron-left prev-arrow"></i>',
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    });

    // work_slider
    $('.work_slider').slick({
        dots: false,
      
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow:'<i class="fa-solid fa-chevron-right next-arrow"></i>',
        nextArrow:'<i class="fa-solid fa-chevron-left prev-arrow"></i>',
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    });

    // product work slider
    $('.workautoplay').slick({
        dots: false, 
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow:'<i class="fa-solid fa-chevron-right next-arrow"></i>',
        nextArrow:'<i class="fa-solid fa-chevron-left prev-arrow"></i>',
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    });

    // header_footer line_slider
    $('.header_footer').slick({
        dots: false, 
        infinite: true,
        prevArrow:'<i class="fa-solid fa-chevron-right next-arrow"></i>',
        nextArrow:'<i class="fa-solid fa-chevron-left prev-arrow"></i>',
        speed: 600,
        slidesToShow: 4,
        autoplay: true,
        slidesToScroll: 1,
        
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    }); 

    // Header responsive
    var harmburg_icon = $('.fa-bars'); // Selecting the hamburger icon
    var close_btn = $('.fa-xmark'); // Selecting the close button

    $('.col-1.d-block.d-lg-none').click(function() {
        var responsiveHeader = $(' .header_py ');

        // Toggle the 'active' class on the responsive header
        responsiveHeader.toggleClass('active_nav'); 
        // Toggle visibility of hamburger icon and close button
        harmburg_icon.toggle();
        close_btn.toggle();
    });

    close_btn.hide();


    // _____CATEGORY PAGE SIDEBAR_____

    $('.sidebar_accordion-content').hide(); // Initially hide all accordion contents
    $('.fa-minus').hide();  
    // Open the first accordion by default
    $('.sidebar_accordion .sidebar_accordion-content').show();

    // Toggle accordion content
    $('.sidebar_accordion-header').click(function(){
        var sidebar_accordion_content = $(this).next('.sidebar_accordion-content');
        var sidebar_plus_icon = $(this).find('.fa-plus');
        var sidebar_minus_icon = $(this).find('.fa-minus');

        // Close all accordion contents except the one clicked
        $('.sidebar_accordion-content').not(sidebar_accordion_content).slideUp();
        $('.sidebar_accordion-header').removeClass('active');
        $('.fa-minus').hide();
        $('.fa-plus').show();

        // Toggle the accordion content
        sidebar_accordion_content.slideToggle(function(){
            if ($(this).css('display') === 'block') {
                $(this).prev('.sidebar_accordion-header').addClass('active');
                sidebar_plus_icon.hide();
                sidebar_minus_icon.show();
            } else {
                $(this).prev('.sidebar_accordion-header').removeClass('active');
                sidebar_plus_icon.show();
                sidebar_minus_icon.hide();
            }
        });
    });

    // Category slider review
    $('.single-item').slick({
        dots: true, // Show navigation dots
        arrows: false // Hide navigation arrows
    });

    //  Category rbait runs slider
    $('.multiple-items').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true, // Show navigation dots
        prevArrow:'<i class="  fa-solid fa-arrow-right next-arrow"></i>',
        nextArrow:'<i class="  fa-solid fa-arrow-left prev-arrow"></i>', 
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    });



    	// product page script start

	  $('.products').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      centerMode: false,
      swipe:true,
      asNavFor: '.product-nav'
    });
    $('.product-nav').slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      asNavFor: '.products',
      arrows: true,
      centerMode: false,
      focusOnSelect: true,
      infinite:true,
      variableWidth: true, 
      responsive: [
  
  
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            focusOnSelect: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            focusOnSelect: true,
            centerMode: false,
            variableWidth: true,
          }
        }
      ]
  
    });
  
    $('.mobile-product').slick({
      dots: false,
      prevArrow:'<i class="fa-solid fa-chevron-right next-arrow"></i>',
      nextArrow:'<i class="fa-solid fa-chevron-left prev-arrow"></i>',
      infinite: true,
      speed: 300,
      slidesToShow: 9,
      slidesToScroll: 1,
      responsive: [
        
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots:true,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots:true,
          }
        },
  
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots:true,
          }
        }
      ]
    });
  
    $('.product-slider').slick({
      dots: true,
      arrows:true,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots:true,
            
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots:true,
            arrows:true,
          }
        },
  
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots:true,
            arrows:true,
          }
        }
      ]
    });
  
    
  
    $('.faq-tabheader').slick({
      dots: false,
      arrows:true,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      // variableWidth: true,
      responsive: [
  
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
          }
        },
  
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          }
        }
      ]
    });
    $('.faq-tabheader').on('afterChange', function(event, slick, currentSlide) {
      // Check if there is an active tab
      var activeTab = $('.faq-tabheader .nav-item.slick-current.slick-active');
      if (activeTab.length > 0) {
          // Trigger click event on the button inside the active tab
          activeTab.find('.nav-link').click();
      }
  });

    
    // rabbite hutch sidebar
    
    $('.secnd_sidebar_accordion-content').hide(); // Initially hide all accordion contents
    $('.fa-minus').hide();  
    // Open the first accordion by default
    $('.secnd_sidebar_accordion  .secnd_sidebar_accordion-content').show();
    
    // Toggle accordion content
    $('.secnd_sidebar_accordion-header').click(function(){
        var sidebar_accordion_content = $(this).next('.secnd_sidebar_accordion-content');
        var sidebar_plus_icon = $(this).find('.fa-plus');
        var sidebar_minus_icon = $(this).find('.fa-minus');
    
        // Close all accordion contents except the one clicked
        $('.secnd_sidebar_accordion-content').not(sidebar_accordion_content).slideUp();
        $('.secnd_sidebar_accordion-header').removeClass('active');
        $('.fa-minus').hide();
        $('.fa-plus').show();
    
        // Toggle the accordion content
        sidebar_accordion_content.slideToggle(function(){
            if ($(this).css('display') === 'block') {
                $(this).prev('.secnd_sidebar_accordion-header').addClass('active');
                sidebar_plus_icon.hide();
                sidebar_minus_icon.show();
            } else {
                $(this).prev('.secnd_sidebar_accordion-header').removeClass('active');
                sidebar_plus_icon.show();
                sidebar_minus_icon.hide();
            }
        });
    });
    
    // third accordion
    $('.third_sidebar_accordion-content').hide(); // Initially hide all accordion contents
    $('.fa-minus').hide();  
    // Open the first accordion by default
    $('.third_sidebar_accordion  .third_sidebar_accordion-content').show();
    
    // Toggle accordion content
    $('.third_sidebar_accordion-header').click(function(){
        var sidebar_accordion_content = $(this).next('.third_sidebar_accordion-content');
        var sidebar_plus_icon = $(this).find('.fa-plus');
        var sidebar_minus_icon = $(this).find('.fa-minus');
    
        // Close all accordion contents except the one clicked
        $('.third_sidebar_accordion-content').not(sidebar_accordion_content).slideUp();
        $('.third_sidebar_accordion-header').removeClass('active');
        $('.fa-minus').hide();
        $('.fa-plus').show();
    
        // Toggle the accordion content
        sidebar_accordion_content.slideToggle(function(){
            if ($(this).css('display') === 'block') {
                $(this).prev('.third_sidebar_accordion-header').addClass('active');
                sidebar_plus_icon.hide();
                sidebar_minus_icon.show();
            } else {
                $(this).prev('.third_sidebar_accordion-header').removeClass('active');
                sidebar_plus_icon.show();
                sidebar_minus_icon.hide();
            }
        });
    });
    
    // Fourth accordion
    $('.fourth_sidebar_accordion-content').hide(); // Initially hide all accordion contents
    $('.fa-minus').hide();  
    // Open the first accordion by default
    $('.fourth_sidebar_accordion .fourth_sidebar_accordion-content').show();
    
    // Toggle accordion content
    $('.fourth_sidebar_accordion-header').click(function(){
        var sidebar_accordion_content = $(this).next('.fourth_sidebar_accordion-content');
        var sidebar_plus_icon = $(this).find('.fa-plus');
        var sidebar_minus_icon = $(this).find('.fa-minus');
    
        // Close all accordion contents except the one clicked
        $('.fourth_sidebar_accordion-content').not(sidebar_accordion_content).slideUp();
        $('.fourth_sidebar_accordion-header').removeClass('active');
        $('.fa-minus').hide();
        $('.fa-plus').show();
    
        // Toggle the accordion content
        sidebar_accordion_content.slideToggle(function(){
            if ($(this).css('display') === 'block') {
                $(this).prev('.fourth_sidebar_accordion-header').addClass('active');
                sidebar_plus_icon.hide();
                sidebar_minus_icon.show();
            } else {
                $(this).prev('.fourth_sidebar_accordion-header').removeClass('active');
                sidebar_plus_icon.show();
                sidebar_minus_icon.hide();
            }
        });
    });

 

    // mobile sub accordion____________________

$('.accordionmenu-content').hide(); // Initially hide all accordion contents
$('.fa-minus').hide(); // Hide all minus icons 

// Toggle accordion content
$('.accordionmenu-header').click(function(){
    var accordion_content = $(this).next('.accordionmenu-content');
    var plus_icon = $(this).find('.fa-plus');
    var minus_icon = $(this).find('.fa-minus');

    // Close all accordion contents except the one clicked
    $('.accordionmenu-content').not(accordion_content).slideUp();
    $('.accordionmenu-header').removeClass('active');
    $('.fa-minus').hide();
    $('.fa-plus').show();

    // Toggle the accordion content
    accordion_content.slideToggle(function(){
        if ($(this).css('display') === 'block') {
            $(this).prev('.accordionmenu-header').addClass('active');
            plus_icon.hide();
            minus_icon.show();
        } else {
            $(this).prev('.accordionmenu-header').removeClass('active');
            plus_icon.show();
            minus_icon.hide();
        }
    });
});


// Secnd_Prouct_accordion large_-------------------

 
  // Open the first accordion content on page load
  $('#accordions-content-1').slideDown();
  
  // Add 'open' class to the first accordion item
  $('.accordions-item').first().addClass('open');

  // Set default styles for the first accordion item
  $('.accordions-header-icon .fa-minus').first().show();
 
  $('.accordions-header-icon .fa-plus').first().hide();

  $('.accordions-trigger').click(function(){
      var target = $(this).data('target');
      var accordionItem = $('#' + target).closest('.accordions-item');
      var accordionIcon = $(this).find('.accordions-header-icon');

      // Close all other accordion items and reset their styles
      $('.accordions-item').not(accordionItem).removeClass('open').find('.accordions-content').slideUp();
      $('.accordions-trigger').not(this).find('.faq-btn').removeClass('bg-success').addClass('bg-secondary');
      $('.accordions-trigger').not(this).find('.accordions-header-icon .fa-minus').hide();
      $('.accordions-trigger').not(this).find('.accordions-header-icon .fa-plus').show();

      if (accordionItem.hasClass('open')) {
          $('#' + target).slideUp();
          accordionItem.removeClass('open');
          accordionIcon.find('.fa-minus').hide();
          accordionIcon.find('.fa-plus').show();
          // Change background color of the button to original color
          $(this).find('.faq-btn').removeClass('bg-success').addClass('bg-secondary');
      } else {
          $('#' + target).slideDown();
          accordionItem.addClass('open');
          accordionIcon.find('.fa-minus').show();
          accordionIcon.find('.fa-plus').hide();
          // Change background color of the button to green
          $(this).find('.faq-btn').removeClass('bg-secondary').addClass('bg-success');
      }
  });

// header responsive
// 
$('#open-menu').click(function(){
  $('.menu').addClass('show'); // Show menu
  $('#open-menu').addClass('d-none'); // Hide open-menu icon
  $('#close-menu').removeClass('d-none'); // Show close-menu icon
});

// When clicking on the close-menu icon
$('#close-menu').click(function(){
  $('.menu').removeClass('show'); // Hide menu
  $('#open-menu').removeClass('d-none'); // Show open-menu icon
  $('#close-menu').addClass('d-none'); // Hide close-menu icon
});
 



$('.contactus').click(function(e) {
  e.preventDefault();
  var targetId = $(this).attr('href');
  console.log(targetId);
  var headerHeight = $('header').outerHeight();
  console.log(headerHeight);
  var targetOffset = $(targetId).offset().top - headerHeight;
  $('html, body').animate({
    scrollTop: targetOffset
  }, 1000);
});
});



// resposive accordion woth button 
$(document).ready(function () {
  $(".at-title").click(function () {
    $(this).toggleClass("active").next(".at-tab").slideToggle().parent().siblings().find(".at-tab").slideUp().prev().removeClass("active");
  });
});

// home page --- Faq
$(document).ready(function () {
  $(".accordion-title").click(function () {
    $(this).toggleClass("active").next(".accordion-tab").slideToggle().parent().siblings().find(".accordion-tab").slideUp().prev().removeClass("active");
  });
});


// scroll saty away to header
// $(document).ready(function() {
//   // Function to handle smooth scrolling to sections
//   $('contactus').click(function(e) {
//     e.preventDefault();
//     var targetId = $(this).attr('href');
//     console.log(targetId);
//     var headerHeight = $('header').outerHeight();
//     console.log(headerHeight);
//     var targetOffset = $(targetId).offset().top - headerHeight;
//     $('html, body').animate({
//       scrollTop: targetOffset
//     }, 1000);
//   });
// });